import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyAVARfuJF-vgc1LN9wA-eEWzClvnk-F-YU",
    authDomain: "toppersacademy-d7966.firebaseapp.com",
    projectId: "toppersacademy-d7966",
    storageBucket: "toppersacademy-d7966.appspot.com",
    messagingSenderId: "1082043605501",
    appId: "1:1082043605501:web:c9c79222d89ad2867ff994",
    measurementId: "G-017SQVRPGT"
};
firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();
var provider = new firebase.auth.GoogleAuthProvider(); 
var storage = firebase.storage();
const db = firebase.firestore();


export {auth , firebase, provider, storage, db};